exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-mnie-jsx": () => import("./../../../src/pages/o-mnie.jsx" /* webpackChunkName: "component---src-pages-o-mnie-jsx" */),
  "component---src-pages-oferta-jsx": () => import("./../../../src/pages/oferta.jsx" /* webpackChunkName: "component---src-pages-oferta-jsx" */),
  "component---src-pages-prywatnosc-jsx": () => import("./../../../src/pages/prywatnosc.jsx" /* webpackChunkName: "component---src-pages-prywatnosc-jsx" */),
  "component---src-pages-publikacje-index-jsx": () => import("./../../../src/pages/publikacje/index.jsx" /* webpackChunkName: "component---src-pages-publikacje-index-jsx" */),
  "component---src-pages-publikacje-poprawczak-nastroju-jsx": () => import("./../../../src/pages/publikacje/poprawczak-nastroju.jsx" /* webpackChunkName: "component---src-pages-publikacje-poprawczak-nastroju-jsx" */),
  "component---src-templates-article-template-article-template-tsx": () => import("./../../../src/templates/ArticleTemplate/ArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-article-template-tsx" */)
}

